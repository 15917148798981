import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Row,
  Form,
  Container,
  Card,
  Image,
  Spinner,
} from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Chip from "@material-ui/core/Chip";
import Icon from "@material-ui/core/Icon";
import styled from "styled-components";
import QRCode from "qrcode.react";
import Barcode from "react-barcode";
import { toAbsoluteUrl } from "../../helpers/Utils";
import $ from "jquery";
import creditCardType from "credit-card-type";
import { PayPalButton } from "react-paypal-button-v2";
import SpinnerLoading from "../../components/Loading/SpinnerLoading";
import {
  getCreditBank,
  sendOmise,
  sendBillSlipInvoice,
  sendBillSlip,
} from "../../redux/actions";
import { connect } from "react-redux";
import { ModalviewPDF } from "./ModalviewPDF";

const { Omise } = window;

const StyledWrapper1 = styled.div`
  input::placeholder {
    color: #5d9cf7;
  }
  .coll_bill {
    text-align: right;
  }
  .colr_bill {
    text-align: left;
  }
  @media (max-width: 768px) {
    .colr_bill {
      text-align: center;
    }
    .coll_bill {
      text-align: center;
    }
  }

  .flip-card {
    background-color: transparent;
    max-width: 350px;
    height: 220px;
    perspective: 1000px;
  }

  .flip-card-inner {
    position: relative;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  /* .flip-card:hover .flip-card-inner, */
  .card_form {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    border-radius: 20px;
    position: absolute;
    width: 100%;
    height: 100%;
    .row {
      width: 100%;
      height: 90%;
      left: 4.5%;
      position: absolute;
    }
    transform: rotateY(180deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-card-front {
    background-image: url("/media/paycard/Front_card_BG.png");
    background-size: 100% 100%;
    color: white;
    transform: rotateY(0deg);
  }

  .flip-card-back {
    background-image: url("/media/paycard/Front_back_BG.png");
    background-size: 100% 100%;
    color: white;
    /* transform: rotateY(180deg); */
  }
  .form-control {
    color: #5d9cf7;
  }

  .bill_tesco:hover,
  .bill_bank:hover {
    background-color: #fff5f6;
  }
`;

const datalist = {
  payment_type: "",
  card_no: "",
  card_holder: "",
  id_Issuer: "",
  card_expires_month: "",
  card_expires_year: "",
  card_cvv: "",
  slip_file: "",
};

const ModalPayment = (props) => {
  // console.log("props", props);

  const monthArr = Array.from({ length: 12 }, (x, i) => {
    let month = i + 1;
    return month.toString().padStart(2, "0");
  });
  const yearArr = Array.from(
    { length: 10 },
    (x, i) => new Date().getFullYear() + i
  );
  const [dataInfo, setdataInfo] = useState(datalist);
  const [type, settype] = useState(1);
  const [flip, setflip] = useState(false);
  const [loadPay, setloadPay] = useState(false);
  const [dataShow, setdataShow] = useState({ label: null, url: null });
  const [modalShowViewPDF, setmodalShowViewPDF] = useState(false);
  const [modalShowSuccessPay, setmodalShowSuccessPay] = useState(false);
  const [modalShowErrorPay, setmodalShowErrorPay] = useState(false);
  const [dataInfoCompany, setdataInfoCompany] = useState(props.company);

  // useEffect(() => {
  //   console.log("dataInfo",dataInfo)
  //  },[dataInfo])

  useEffect(() => {
    if (!props.show) {
      setdataShow({ label: null, url: null });
      setdataInfo(datalist);
      settype(0);
      setflip(false);
      setloadPay(false);
    } else {
      setdataInfoCompany(props.company);
      // props.getCreditBank("en");
      if (props.company.cash.check) changeType(0);
      else if (props.company.credit.check) changeType(1);
      else if (props.company.paypal.check) changeType(2);
      else if (props.company.bill.check) changeType(3);
      else changeType(4);
    }
  }, [props.show]);

  const changeType = (value) => {
    settype(value);
    if (value == 0) {
      setdataInfo({ ...datalist, payment_type: "Cash" });
    } else if (value == 1) {
      setdataInfo({ ...datalist, payment_type: "Credit/Debit Cards" });
    } else if (value == 2) {
      setdataInfo({ ...datalist, payment_type: "PayPal" });
    } else if (value == 3) {
      setdataInfo({ ...datalist, payment_type: "" });
    } else {
      setdataInfo({ ...datalist, payment_type: "Transfer" });
    }
  };

  const changeData = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (e.target.name == "slip_file") {
      let file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = function (event) {
        // console.log(file);
        value = { name: file.name, url: event.target.result };
        setdataInfo({
          ...dataInfo,
          slip_file: value,
        });
      };
      reader.readAsDataURL(file);
    } else setdataInfo({ ...dataInfo, [name]: value });
    defaultColor(name);
  };

  const creditType = (type) => {
    let credit = creditCardType(dataInfo.card_no);
    // console.log(credit);
    if (credit.length == 1) {
      if (credit[0].type == "visa") {
        if (type == "img") return toAbsoluteUrl("/media/paycard/visa.png");
        else if (type == "cvv") return credit[0].code.size;
      } else if (credit[0].type == "mastercard") {
        if (type == "img") return toAbsoluteUrl("/media/paycard/MASTER.png");
        else if (type == "cvv") return credit[0].code.size;
      } else if (credit[0].type == "jcb") {
        if (type == "img") return toAbsoluteUrl("/media/paycard/jcb.png");
        else if (type == "cvv") return credit[0].code.size;
      } else {
        if (type == "img") return null;
        else if (type == "cvv") return 0;
      }
    } else {
      if (type == "img") return null;
      else if (type == "cvv") return 0;
    }
  };

  const defaultColor = (name) => {
    $("[name='" + name + "_label']").css({ color: "#003581" });
    $("[name='" + name + "']").removeAttr("required");
  };

  const validateColor = (name) => {
    $("[name='" + name + "_label']").css({ color: "red" });
    $("[name='" + name + "']").attr("required", "true");
  };

  const fill_data = (data) => {
    let newdata = data;
    let data_ignore = ["slip_file"];
    data_ignore.forEach((doc) => {
      newdata = newdata.filter((doc2) => doc2 != doc);
    });
    return newdata;
  };

  const checkValidate = () => {
    let validate = true;
    const obj = fill_data(Object.keys(datalist));
    obj.forEach((data) => {
      if (dataInfo[data].toString() == "") {
        validateColor(data);
        validate = false;
      }
    });
    return validate;
  };

  const SubmitPay = () => {
    if (type == 1) {
      if (checkValidate()) {
        let card = {
          name: dataInfo.card_holder,
          number: dataInfo.card_no,
          expiration_month: dataInfo.card_expires_month,
          expiration_year: dataInfo.card_expires_year,
          security_code: dataInfo.card_cvv,
        };
        // Omise.setPublicKey("pkey_test_5nny1kkzu16h1hns73z");
        Omise.setPublicKey(dataInfoCompany.credit.public_key);
        Omise.createToken("card", card, (statusCode, response) => {
          // console.log(response);
          if (response.object == "error") {
            const obj = fill_data(Object.keys(datalist));
            obj.forEach((data) => {
              validateColor(data);
            });
          } else {
            setloadPay(true);
            let charges = {
              amount: parseFloat(
                parseFloat(props.customerINvoice[0].total)
                  .toFixed(2)
                  .toString()
                  .split(".")
                  .join("")
              ),
              currency: "thb",
              description: `Charge for Invoice No. ${props.customerINvoice[0].invoice_no}`,
              card: response.id,
              public_key: dataInfoCompany.credit.public_key,
              secret_key: dataInfoCompany.credit.secret_key,
              id_invoiceproject: props.customerINvoice[0].id_invoiceproject,
              payment_type: dataInfo.payment_type,
            };
            props.sendOmise(charges);
            setTimeout(() => {
              setloadPay(false);
              // const senddata = {
              //   id_invoiceproject: props.customerINvoice[0].id_invoiceproject,
              //   payment_type: dataInfo.payment_type,
              // };
            }, 3000);
            // setTimeout(() => props.onHide(), 3500);
          }
        });
      }
    } else {
      if (dataInfo.slip_file == "" || dataInfo.payment_type == "") {
        validateColor("slip_file");
      } else {
        const data = {
          id_invoiceproject: props.customerINvoice[0].id_invoiceproject,
          slip_file: dataInfo.slip_file,
          payment_type: dataInfo.payment_type,
        };
        props.sendBillSlip(data);
        setmodalShowSuccessPay(true);
        // setTimeout(() => props.onHide(), 500);
      }
    }
  };

  const show_payment = () => {
    if (type == 0) {
      return "";
    } else if (type == 1) {
      return (
        <>
          <Col xs={12} lg={6}>
            <div>
              <div className="flip-card">
                <div className={`flip-card-inner ${flip ? "card_form" : ""}`}>
                  <div className="flip-card-front">
                    <Row
                      className="mt-3"
                      style={{ color: "white", alignItems: "center" }}
                    >
                      <Col xs={12}>
                        <div
                          className="card_form_bank"
                          style={{ lineHeight: "100%" }}
                        >
                          {dataInfo.id_Issuer != ""
                            ? props.selectCreditBank.find(
                                (doc) => doc.value == dataInfo.id_Issuer
                              ).label
                            : "BANK NAME"}
                        </div>
                      </Col>
                      <Col xs={12} style={{ textAlign: "right" }}>
                        <i className="mr-2">INVOIZE.ME</i>
                      </Col>
                      <Col>
                        <Image
                          className="ml-3"
                          src={toAbsoluteUrl("/media/paycard/chip.png")}
                          style={{
                            width: "35%",
                          }}
                        />
                      </Col>
                      <Col style={{ textAlign: "right" }}>
                        <Image
                          className="mr-3"
                          src={toAbsoluteUrl("/media/paycard/Wire.png")}
                          style={{
                            width: "15%",
                          }}
                        />
                      </Col>
                      <Col
                        xs={12}
                        style={{ fontSize: "180%", textAlign: "center" }}
                      >
                        <div className="card_form_cnum">
                          {dataInfo.card_no.length != 0
                            ? dataInfo.card_no
                                .split(" ")
                                .join("")
                                .split("")
                                .map((x, index) => {
                                  if (index == 3) return `${x} `;
                                  else if (index > 11 || index < 4) return x;
                                  else if ((index + 1) % 4 == 0) return "* ";
                                  else return "*";
                                })
                                .join("")
                            : "#### **** **** ####"}
                        </div>
                      </Col>
                      <Col>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "60%",
                              lineHeight: "100%",
                              opacity: "0.9",
                            }}
                          >
                            VALID
                            <br />
                            THRU
                          </span>
                          <div className="ml-1">
                            <div style={{ fontSize: "60%", opacity: "0.9" }}>
                              MONTH/YEAR
                            </div>
                            <div
                              className="card_form_exp"
                              style={{ textAlign: "center" }}
                            >
                              {dataInfo.card_expires_month != ""
                                ? dataInfo.card_expires_month
                                : "MM"}
                              /
                              {dataInfo.card_expires_year != ""
                                ? dataInfo.card_expires_year
                                    .toString()
                                    .slice(-2)
                                : "YY"}
                            </div>
                          </div>
                        </div>
                        <div
                          className="card_form_nholder"
                          style={{
                            fontSize: "90%",
                          }}
                        >
                          {dataInfo.card_holder != ""
                            ? dataInfo.card_holder
                            : "FULL NAME"}
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div
                          style={{
                            backgroundColor: "rgba(60, 60, 60, 0.7)",
                            textAlign: "center",
                            borderRadius: "6px",
                            padding: `${
                              creditType("img") != null ? "0%" : "10%"
                            }`,
                          }}
                        >
                          <Image
                            src={creditType("img")}
                            style={{ width: "80%" }}
                          />
                        </div>
                      </Col>
                      <Col
                        className="mb-1"
                        xs={12}
                        style={{
                          textAlign: "right",
                          fontSize: "40%",
                          opacity: "0.9",
                        }}
                      >
                        Copyright {new Date().getFullYear()} © Phuket Innovative
                        Development Co., Ltd.
                      </Col>
                    </Row>
                  </div>
                  <div className="flip-card-back">
                    <div>
                      <div
                        style={{
                          fontSize: "40%",
                          opacity: "0.9",
                          position: "absolute",
                          top: "3%",
                          left: "5%",
                        }}
                      >
                        Contact Support : biz@pkindev.com
                      </div>
                      <div
                        style={{
                          fontSize: "40%",
                          opacity: "0.9",
                          position: "absolute",
                          top: "6%",
                          right: "5%",
                        }}
                      >
                        Copyright {new Date().getFullYear()} © Phuket Innovative
                        Development Co., Ltd.
                      </div>
                      <div
                        style={{
                          backgroundColor: "black",
                          padding: "5%",
                          width: "100%",
                          position: "absolute",
                          left: "0%",
                          top: "10%",
                        }}
                      ></div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "90%",
                          marginTop: "70px",
                        }}
                      >
                        <div className="ml-3">
                          <Image
                            src={toAbsoluteUrl("/media/paycard/Signature.png")}
                            style={{ width: "100%" }}
                          />
                          <div
                            className="mt-1"
                            style={{
                              fontSize: "40%",
                              opacity: "0.9",
                            }}
                          >
                            Copyright {new Date().getFullYear()} © Phuket
                            Innovative Development Co., Ltd.
                          </div>
                        </div>
                        <div className="ml-2">
                          <div
                            style={{
                              backgroundColor: "white",
                              color: "black",
                              height: "75%",
                              width: "210%",
                            }}
                          >
                            <div>
                              <b>
                                <div
                                  style={{
                                    textAlign: "right",
                                    fontSize: "70%",
                                    width: "95%",
                                  }}
                                >
                                  CVV
                                </div>
                                <div style={{ textAlign: "center" }}>
                                  {"".padStart(dataInfo.card_cvv.length, "*")}
                                </div>
                              </b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div
                          className="mr-2"
                          style={{
                            backgroundColor: "rgba(60, 60, 60, 0.7)",
                            textAlign: "center",
                            borderRadius: "6px",
                            width: "23%",
                            paddingTop: `${
                              creditType("img") != null ? "0%" : "2%"
                            }`,
                            paddingBottom: `${
                              creditType("img") != null ? "0%" : "2%"
                            }`,
                          }}
                        >
                          <Image
                            src={creditType("img")}
                            style={{ width: "90%" }}
                          />
                        </div>
                      </div>
                      <div
                        className="mt-4"
                        style={{
                          textAlign: "center",
                          fontSize: "40%",
                        }}
                      >
                        By registering the payment information above, you are
                        authorizing{" "}
                        {dataInfoCompany.name_company_en.toLowerCase()}. <br />
                        to charge your payment method immediately for any due or
                        past due invoices. <br /> You may modify this payment at
                        any time by logging into your account.
                      </div>
                      <div
                        className="mt-1"
                        style={{
                          textAlign: "center",
                          fontSize: "40%",
                        }}
                      >
                        https://www.pkindev.com
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <Card
                className={flip ? "card_form" : ""}
                style={{
                  borderRadius: "20px",
                  // backgroundColor: "#FFF5F6",
                  // border: "2px solid #D33D4C",
                  height: "220px",
                  maxWidth: "350px",
                  backgroundImage: `url(${toAbsoluteUrl(
                    "/media/paycard/Front_card_BG.png"
                  )})`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                }}
              ></Card> */}
            </div>
            <div className="mt-2">
              <Form.Group>
                <Form.Label name="id_Issuer_label" style={{ color: "#003581" }}>
                  Issuer Bank *
                </Form.Label>
                <Form.Control
                  as="select"
                  name="id_Issuer"
                  onChange={(e) => changeData(e)}
                  value={dataInfo.id_Issuer}
                  onFocus={() =>
                    $(".card_form_bank").css({ border: "1px solid white" })
                  }
                  onBlur={() => $(".card_form_bank").css({ border: "none" })}
                >
                  <option value="">Issuer Bank</option>
                  {props.selectCreditBank.map((doc) => (
                    <option value={doc.value}>{doc.label}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </div>
          </Col>
          <Col xs={12} lg={6} className="mt-4">
            <Form style={{ color: "#003581" }}>
              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label name="card_holder_label">
                      Cardholder Name *
                      <span
                        style={{
                          position: "absolute",
                          right: "0%",
                          top: "-5%",
                        }}
                      >
                        <Image
                          src={toAbsoluteUrl("/assets/img/credit-all.png")}
                          width="100"
                          height="20"
                        />
                      </span>
                    </Form.Label>
                    <Form.Control
                      name="card_holder"
                      placeholder="Cardholder Name"
                      onChange={(e) => changeData(e)}
                      value={dataInfo.card_holder}
                      maxLength={20}
                      onFocus={() =>
                        $(".card_form_nholder").css({
                          border: "1px solid white",
                        })
                      }
                      onBlur={() =>
                        $(".card_form_nholder").css({ border: "none" })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label name="card_no_label">Card Number *</Form.Label>
                    <Form.Control
                      name="card_no"
                      placeholder="Card Number"
                      onChange={(e) => changeData(e)}
                      value={
                        dataInfo.card_no != ""
                          ? dataInfo.card_no
                              .split(" ")
                              .join("")
                              .match(/.{1,4}/g)
                              .join(" ")
                          : dataInfo.card_no
                      }
                      maxLength={19}
                      onFocus={() =>
                        $(".card_form_cnum").css({ border: "1px solid white" })
                      }
                      onBlur={() =>
                        $(".card_form_cnum").css({ border: "none" })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Form.Label>
                    <u>Expiry Date</u>
                  </Form.Label>
                </Col>
                <Col xs={12} sm={4}>
                  <Form.Group>
                    <Form.Label name="card_expires_month_label">
                      Month *
                    </Form.Label>
                    <Form.Control
                      name="card_expires_month"
                      as="select"
                      onChange={(e) => changeData(e)}
                      value={dataInfo.card_expires_month}
                      onFocus={() =>
                        $(".card_form_exp").css({
                          border: "1px solid white",
                        })
                      }
                      onBlur={() => $(".card_form_exp").css({ border: "none" })}
                    >
                      <option value="">Month</option>
                      {monthArr.map((doc) => (
                        <option value={doc}>{doc}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={4}>
                  <Form.Group>
                    <Form.Label name="card_expires_year_label">
                      Year *
                    </Form.Label>
                    <Form.Control
                      name="card_expires_year"
                      as="select"
                      onChange={(e) => changeData(e)}
                      value={dataInfo.card_expires_year}
                      onFocus={() =>
                        $(".card_form_exp").css({
                          border: "1px solid white",
                        })
                      }
                      onBlur={() => $(".card_form_exp").css({ border: "none" })}
                    >
                      <option value="">Year</option>
                      {yearArr.map((doc) => (
                        <option value={doc}>{doc}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col xs={12} sm={4}>
                  <Form.Group>
                    <Form.Label name="card_cvv_label">CVV *</Form.Label>
                    <Form.Control
                      type="password"
                      name="card_cvv"
                      placeholder="CVV"
                      onChange={(e) => changeData(e)}
                      value={dataInfo.card_cvv}
                      maxLength={creditType("cvv")}
                      onFocus={() => {
                        setflip(true);
                        if (creditType("cvv") == 0) validateColor("card_no");
                      }}
                      onBlur={() => {
                        setflip(false);
                        defaultColor("card_no");
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Col>
        </>
      );
    } else if (type == 2) {
      return (
        <>
          <Col className="mt-3">
            <div
              style={{
                margin: "auto",
                maxWidth: "300px",
              }}
            >
              {/* <PayPalButton
                createOrder={(data, actions) => {
                  return actions.order.create({
                    purchase_units: [
                      {
                        amount: {
                          // currency_code: "USD",
                          value: `${parseFloat(
                            props.customerINvoice.total_price * 1.07
                          ).toFixed(2)}`,
                        },
                      },
                    ],
                    // application_context: {
                    //   shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
                    // }
                  });
                }}
                onApprove={(data, actions) => {
                  // Capture the funds from the transaction
                  return actions.order.capture().then(function (details) {
                    // Show a success message to your buyer
                    // alert(
                    //   "Transaction completed by " +
                    //     details.payer.name.given_name
                    // );
                    const senddata = {
                      id_contract_agreement:
                        props.customerINvoice.id_contract_agreement,
                      payment_type: dataInfo.payment_type,
                      id_project: props.customerINvoice.id_project,
                    };
                    props.showSuccess();
                    props.sendBillSlipInvoice(senddata);

                    // OPTIONAL: Call your server to save the transaction
                    // return fetch("/paypal-transaction-complete", {
                    //   method: "post",
                    //   body: JSON.stringify({
                    //     orderID: data.orderID,
                    //   }),
                    // });
                  });
                }}
                options={{
                  // clientId:
                  //   "AbCKZ9UFcodbw3EjrXOkAq6Xm3hYtHq2yjeNZKspGlEBM6hZFr7xjuu-NxkUezULYLUgX2txr7hFt6sX",
                  clientId: dataInfoCompany.paypal.client_id,
                  currency: "THB",
                  disableFunding: "credit,card",
                }}
                style={{ height: 45, shape: "pill", label: "checkout" }}
              /> */}
            </div>
          </Col>
        </>
      );
    } else if (type == 3) {
      return (
        <>
          {/* <Col className="coll_bill mt-3">
            <Form>
              <Form.Group>
                <Form.Label
                  className="bill_tesco"
                  htmlFor="bill1"
                  style={{
                    border: "1px solid #D33D4C",
                    borderRadius: "20px",
                    padding: "19px",
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    backgroundColor: `${
                      dataInfo.payment_type == "Bill Payment (TESCO LOTUS)"
                        ? "#FFF5F6"
                        : ""
                    }`,
                    cursor: "pointer",
                  }}
                >
                  <Image
                    src={toAbsoluteUrl("/assets/img/lotus.png")}
                    height="40"
                  />
                </Form.Label>
                <Form.Check
                  id="bill1"
                  name="payment_type"
                  value="Bill Payment (TESCO LOTUS)"
                  checked={
                    dataInfo.payment_type == "Bill Payment (TESCO LOTUS)"
                  }
                  onChange={(e) => changeData(e)}
                  style={{ display: "none" }}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col className="colr_bill mt-3">
            <Form>
              <Form.Group>
                <Form.Label
                  className="bill_bank"
                  htmlFor="bill2"
                  style={{
                    border: "1px solid #D33D4C",
                    borderRadius: "20px",
                    padding: "17px",
                    paddingLeft: "45px",
                    paddingRight: "45px",
                    backgroundColor: `${
                      dataInfo.payment_type == "Bill Payment (BANK)"
                        ? "#FFF5F6"
                        : ""
                    }`,
                    cursor: "pointer",
                  }}
                >
                  <Image
                    src={toAbsoluteUrl("/media/paycard/Bill payment.png")}
                    height="45"
                  />
                </Form.Label>
                <Form.Check
                  id="bill2"
                  name="payment_type"
                  value="Bill Payment (BANK)"
                  checked={dataInfo.payment_type == "Bill Payment (BANK)"}
                  onChange={(e) => changeData(e)}
                  style={{ display: "none" }}
                />
              </Form.Group>
            </Form>
          </Col>
          <Col xs={12} style={{ textAlign: "center", color: "#003581" }}>
            {dataInfo.payment_type == "Bill Payment (BANK)" ||
            dataInfo.payment_type == "Bill Payment (TESCO LOTUS)" ? (
              <Form>
                <Form.Group>
                  <Row>
                    <Form.Label column className="coll_bill" xs={12} sm={6}>
                      Attached Slip File(s) (PDF,JPG,PNG)
                    </Form.Label>
                    <Col style={{ textAlign: "left" }}>
                      {dataInfo.slip_file == "" ? (
                        <>
                          <Form.Label htmlFor="slip_file">
                            <div
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                                border: "1px solid #D33D4C",
                                cursor: "pointer",
                              }}
                            >
                              <div
                                style={{
                                  marginLeft: "4px",
                                  marginTop: "2px",
                                }}
                              >
                                <Icon
                                  className="fas fa-plus"
                                  style={{ color: "#D33D4C" }}
                                />
                              </div>
                            </div>
                          </Form.Label>
                          <Form.File
                            id="slip_file"
                            name="slip_file"
                            onChange={(e) => changeData(e)}
                            accept={"application/pdf, image/jpeg, image/png"}
                            style={{ display: "none" }}
                          />
                        </>
                      ) : (
                        <Chip
                          label={
                            dataInfo.slip_file.name.length > 20
                              ? dataInfo.slip_file.name
                                  .substr(0, 15)
                                  .padEnd(20, ".")
                              : dataInfo.slip_file.name
                          }
                          onDelete={() =>
                            setdataInfo({
                              ...dataInfo,
                              slip_file: "",
                            })
                          }
                          onClick={() => {
                            setdataShow({
                              label: dataInfo.slip_file.name,
                              url: dataInfo.slip_file.url,
                            });
                            setmodalShowViewPDF(true);
                          }}
                          deleteIcon={
                            <Icon
                              className="fas fa-times"
                              style={{ color: "white", fontSize: "15px" }}
                            />
                          }
                          style={{
                            background: "red",
                            color: "white",
                            fontSize: "13px",
                            height: "30px",
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
            ) : null}
          </Col> */}
          <Col xs={12}>
            <Form
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              {dataInfoCompany.bill.lotus.check ? (
                <Form.Group>
                  <Form.Label
                    className="bill_tesco mr-3"
                    htmlFor="bill1"
                    style={{
                      border: "1px solid #D33D4C",
                      borderRadius: "20px",
                      padding: "19px",
                      paddingLeft: "50px",
                      paddingRight: "50px",
                      backgroundColor: `${
                        dataInfo.payment_type == "Bill Payment (TESCO LOTUS)"
                          ? "#FFF5F6"
                          : ""
                      }`,
                      cursor: "pointer",
                    }}
                  >
                    <Image
                      src={toAbsoluteUrl("/assets/img/lotus.png")}
                      height="40"
                    />
                  </Form.Label>
                  <Form.Check
                    id="bill1"
                    name="payment_type"
                    value="Bill Payment (TESCO LOTUS)"
                    checked={
                      dataInfo.payment_type == "Bill Payment (TESCO LOTUS)"
                    }
                    onChange={(e) => changeData(e)}
                    style={{ display: "none" }}
                  />
                </Form.Group>
              ) : null}
              {dataInfoCompany.bill.bank.check ? (
                <Form.Group>
                  <Form.Label
                    className="bill_bank  mr-3"
                    htmlFor="bill2"
                    style={{
                      border: "1px solid #D33D4C",
                      borderRadius: "20px",
                      padding: "17px",
                      paddingLeft: "45px",
                      paddingRight: "45px",
                      backgroundColor: `${
                        dataInfo.payment_type == "Bill Payment (BANK)"
                          ? "#FFF5F6"
                          : ""
                      }`,
                      cursor: "pointer",
                    }}
                  >
                    <Image
                      src={toAbsoluteUrl("/media/paycard/Bill payment.png")}
                      height="45"
                    />
                  </Form.Label>
                  <Form.Check
                    id="bill2"
                    name="payment_type"
                    value="Bill Payment (BANK)"
                    checked={dataInfo.payment_type == "Bill Payment (BANK)"}
                    onChange={(e) => changeData(e)}
                    style={{ display: "none" }}
                  />
                </Form.Group>
              ) : null}
            </Form>
          </Col>
          <Col xs={12} style={{ textAlign: "center", color: "#003581" }}>
            {dataInfo.payment_type == "Bill Payment (BANK)" ||
            dataInfo.payment_type == "Bill Payment (TESCO LOTUS)" ? (
              <Form>
                <Form.Group>
                  <Row>
                    <Form.Label column className="coll_bill" xs={12} sm={6}>
                      Attached Slip File(s) (PDF,JPG,PNG)
                    </Form.Label>
                    <Col style={{ textAlign: "left" }}>
                      {dataInfo.slip_file == "" ? (
                        <>
                          <Form.Label htmlFor="slip_file">
                            <div
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                                border: "1px solid #D33D4C",
                                cursor: "pointer",
                              }}
                            >
                              <div
                                style={{
                                  marginLeft: "4px",
                                  marginTop: "2px",
                                }}
                              >
                                <Icon
                                  className="fas fa-plus"
                                  style={{ color: "#D33D4C" }}
                                />
                              </div>
                            </div>
                          </Form.Label>
                          <Form.File
                            id="slip_file"
                            name="slip_file"
                            onChange={(e) => changeData(e)}
                            accept={"application/pdf, image/jpeg, image/png"}
                            style={{ display: "none" }}
                          />
                        </>
                      ) : (
                        <Chip
                          label={
                            dataInfo.slip_file.name.length > 20
                              ? dataInfo.slip_file.name
                                  .substr(0, 15)
                                  .padEnd(20, ".")
                              : dataInfo.slip_file.name
                          }
                          onDelete={() =>
                            setdataInfo({
                              ...dataInfo,
                              slip_file: "",
                            })
                          }
                          onClick={() => {
                            setdataShow({
                              label: dataInfo.slip_file.name,
                              url: dataInfo.slip_file.url,
                            });
                            setmodalShowViewPDF(true);
                          }}
                          deleteIcon={
                            <Icon
                              className="fas fa-times"
                              style={{ color: "white", fontSize: "15px" }}
                            />
                          }
                          style={{
                            background: "red",
                            color: "white",
                            fontSize: "13px",
                            height: "30px",
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </Form>
            ) : null}
          </Col>
        </>
      );
    } else {
      return (
        <>
          <Col xs={12} style={{ color: "#003581" }}>
            <Form
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <Form.Group>
                <Form.Label className="coll_bill mr-2">
                  <img
                    src={
                      dataInfoCompany.transfer.id_bank
                        ? `data:image/png;base64,${
                            props.bankPayment.find(
                              (doc) =>
                                doc.id_bank == dataInfoCompany.transfer.id_bank
                            ).bank_icon
                          }`
                        : null
                    }
                    height="80"
                  />
                  Attached Slip File(s) (PDF,JPG,PNG)
                </Form.Label>
              </Form.Group>

              <Form.Group>
                {dataInfo.slip_file == "" ? (
                  <>
                    <Form.Label htmlFor="slip_file">
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          border: "1px solid #D33D4C",
                          cursor: "pointer",
                        }}
                      >
                        <div
                          style={{
                            marginLeft: "4px",
                            marginTop: "2px",
                          }}
                        >
                          <Icon
                            className="fas fa-plus"
                            style={{ color: "#D33D4C" }}
                          />
                        </div>
                      </div>
                    </Form.Label>
                    <Form.Control
                      type="file"
                      id="slip_file"
                      name="slip_file"
                      onChange={(e) => changeData(e)}
                      accept={"application/pdf, image/jpeg, image/png"}
                      style={{ display: "none" }}
                    />
                  </>
                ) : (
                  <Chip
                    label={
                      dataInfo.slip_file.name.length > 20
                        ? dataInfo.slip_file.name.substr(0, 15).padEnd(20, ".")
                        : dataInfo.slip_file.name
                    }
                    onDelete={() =>
                      setdataInfo({
                        ...dataInfo,
                        slip_file: "",
                      })
                    }
                    onClick={() => {
                      setdataShow({
                        label: dataInfo.slip_file.name,
                        url: dataInfo.slip_file.url,
                      });
                      setmodalShowViewPDF(true);
                    }}
                    deleteIcon={
                      <Icon
                        className="fas fa-times"
                        style={{ color: "white", fontSize: "15px" }}
                      />
                    }
                    style={{
                      background: "red",
                      color: "white",
                      fontSize: "13px",
                      height: "30px",
                      marginTop: "-10px",
                    }}
                  />
                )}
              </Form.Group>
            </Form>
          </Col>
        </>
      );
    }
  };

  return (
    <div>
      <Modal isOpen={props.show} toggle={props.onHide} size="lg">
        <ModalHeader
          //toggle={props.onHide}
          style={{ border: 0 }}
          className="pb-0"
        >
          <h2>Payment Detail</h2>

          <button
            onClick={props.onHide}
            style={{
              position: "absolute",
              right: "28px",
              top: "20px",
            }}
            type="button"
            class="close"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </ModalHeader>
        <ModalBody>
          <StyledWrapper1>
            <Row>
              <Col sm={12}>
                <Form.Group
                  style={{
                    color: "#003581",
                  }}
                >
                  <Row>
                    {dataInfoCompany.cash.check ? (
                      <Col xs={12} md={5} lg={3}>
                        <Form.Check
                          name="payment_type"
                          className="ml-2"
                          type="radio"
                          label="Cash"
                          value={0}
                          checked={type == 0}
                          onChange={(e) => changeType(e.target.value)}
                        />
                      </Col>
                    ) : null}
                    {dataInfoCompany.credit.check &&
                    dataInfoCompany.credit.enable ? (
                      <Col xs={12} md={5} lg={3}>
                        <Form.Check
                          name="payment_type"
                          className="ml-2"
                          type="radio"
                          label="Credit / Debit Cards"
                          value={1}
                          checked={type == 1}
                          onChange={(e) => changeType(e.target.value)}
                        />
                      </Col>
                    ) : null}
                    {dataInfoCompany.paypal.check &&
                    dataInfoCompany.paypal.enable ? (
                      <Col xs={12} md={3} lg={2}>
                        <Form.Check
                          name="payment_type"
                          className="ml-2"
                          type="radio"
                          label="PayPal"
                          value={2}
                          checked={type == 2}
                          onChange={(e) => changeType(e.target.value)}
                        />
                      </Col>
                    ) : null}
                    {dataInfoCompany.bill.check &&
                    dataInfoCompany.bill.enable ? (
                      <Col xs={12} md={4} lg={3}>
                        <Form.Check
                          name="payment_type"
                          className="ml-2"
                          type="radio"
                          label="Bill Payment"
                          value={3}
                          checked={type == 3}
                          onChange={(e) => changeType(e.target.value)}
                        />
                      </Col>
                    ) : null}
                    {dataInfoCompany.transfer.check &&
                    dataInfoCompany.transfer.enable ? (
                      <Col xs={12} md={4} lg={3}>
                        <Form.Check
                          name="payment_type"
                          className="ml-2"
                          type="radio"
                          label="Bank Transfer"
                          value={4}
                          checked={type == 4}
                          onChange={(e) => changeType(e.target.value)}
                          // onChange={(e) => console.log("e.target.value =",e.target.value)}
                        />
                      </Col>
                    ) : null}
                  </Row>
                </Form.Group>
              </Col>
              {show_payment()}
              <Col xs={12} className="mt-3">
                By registering the payment information above, you are
                authorizing {dataInfoCompany.name_company_en.toLowerCase()}. to
                charge your payment method immediately for any due or past due
                invoices. You may modify this payment at any time by logging
                into your account.
              </Col>
            </Row>
          </StyledWrapper1>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "center", alignItems: "center" }}>
          <Button
            name="btn_close"
            variant="light"
            onClick={props.onHide}
            style={{
              borderRadius: "20px",
              height: "20px",
              width: "100px",
              borderColor: "#E26949",
              color: "#E26949",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>Cancel</div>
          </Button>
          {type == 0 || type == 3 || type == 4 ? (
            <Button
              name="btn_save"
              variant="danger"
              onClick={SubmitPay}
              style={{
                borderRadius: "20px",
                height: "20px",
                width: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>Submit</div>
            </Button>
          ) : type == 1 ? (
            <Button
              name="btn_save"
              variant="danger"
              onClick={SubmitPay}
              style={{
                borderRadius: "20px",
                height: "20px",
                width: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* {loadPay ? (
                <Spinner
                  className="mr-1"
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null} */}
              Pay
            </Button>
          ) : null}
        </ModalFooter>
      </Modal>

      <ModalviewPDF
        show={modalShowViewPDF}
        onHide={() => setmodalShowViewPDF(false)}
        data={dataShow}
      />
      {/* <ModalSuccessPay
        show={modalShowSuccessPay}
        onHide={() => {
          setmodalShowSuccessPay(false);
          props.onHide();
        }}
      /> */}
      <ModalSuccessPay
        show={modalShowSuccessPay}
        onHide={() => {
          setmodalShowSuccessPay(false);
          props.onHide && props.onHide(); // ตรวจสอบว่า props.onHide ถูกเรียกอย่างถูกต้อง
        }}
      />
      <ModalErrorPay
        show={modalShowErrorPay}
        onHide={() => {
          setmodalShowErrorPay(false);
          props.onHide();
        }}
      />
      <SpinnerLoading modal={props.loading || loadPay} />
    </div>
  );
};

export const ModalSuccessPay = (props) => {
  const history = useHistory();
  return (
    <Modal size="md" 
    isOpen={props.show} 
    toggle={props.onHide}
    >
      <ModalHeader 
      //toggle={props.onHide}

      >
        <button
        onClick={props.onHide}
        style={{
          position: "absolute",
          right: "28px",
          top: "20px",
        }}
        type="button"
        class="close"
        aria-label="Close"
      >        
      </button>
        Thank you your payment

      </ModalHeader>

      <ModalBody>
        <h6>Your payment has been successful.</h6>
      </ModalBody>
      <ModalFooter style={{ justifyContent: "center", alignItems: "center" }}>
        <Button
          name="btn_close"
          variant="light"
          // onClick={props.onHide}
          onClick={() => {
            history.push("/");
            window.location.reload();
          }}
          style={{
            borderRadius: "20px",
            height: "20px",
            width: "100px",
            borderColor: "#E26949",
            color: "#E26949",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>Close</div>
        </Button>
      </ModalFooter>
    </Modal>
  );
};
// export const ModalErrorPay = (props) => {
//   return (
//     <Modal size="md" isOpen={props.show} toggle={props.onHide}>
//       <ModalHeader toggle={props.onHide}>Alert</ModalHeader>
//       <ModalBody>
//         <h6>payment failed Please select another payment method.</h6>
//       </ModalBody>
//       <ModalFooter style={{ justifyContent: "center", alignItems: "center" }}>
//         <Button
//           name="btn_close"
//           variant="light"
//           onClick={props.onHide}
//           style={{
//             borderRadius: "20px",
//             height: "20px",
//             width: "100px",
//             borderColor: "#E26949",
//             color: "#E26949",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <div>Close</div>
//         </Button>
//       </ModalFooter>
//     </Modal>
//   );
// };

const ModalErrorPay = (props) => {
  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter">
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ color: "#003581" }}
        >
          Alert
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ color: "#003581" }}>
        <h6>payment failed Please select another payment method.</h6>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "center", alignItems: "center" }}>
        <Button
          name="btn_close"
          variant="light"
          onClick={props.onHide}
          style={{ color: "#003581", borderRadius: "10px" }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = ({ settings, customerInvoice }) => {
  const { loading, selectCreditBank } = settings;
  const { customerINvoice, bankPayment, omiseSuccess } = customerInvoice;
  // console.log(" mapStateToProps ", settings);
  // console.log(" mapStateToProps ", customerInvoice);
  return {
    loading,
    customerINvoice,
    selectCreditBank,
    bankPayment,
    omiseSuccess,
  };
};

export default connect(mapStateToProps, {
  getCreditBank,
  sendOmise,
  sendBillSlipInvoice,
  sendBillSlip,
})(ModalPayment);
