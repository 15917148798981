import {
  COMPANY_PROFILE_ADD,
  COMPANY_PROFILE_GET,
  COMPANY_PROFILE_UPDATE,
  COMPANY_PROFILE_DELETE,
  COMPANY_PROFILE_COUNTRY,
  COMPANY_PROFILE_PROVINCE,
  COMPANY_PROFILE_DISTRICT,
  COMPANY_PROFILE_SUBDISTRICT,
  // COMPANY_PROFILE_ZIPCODE,
  CREDITBANK_GET,
  COMPANY_PKINDEV,
  PROJECT_ADD,
  PROJECT_GET,
  PROJECT_GET_INVOICE,
  PROJECT_SET,
  PROJECT_UPDATE,
  PROJECT_DELETE,
  USER_ADD,
  USER_GET,
  USER_UPDATE,
  USER_PASS_UPDATE,
  USER_DELETE,
  ROLE_GET,
  ROLE_ADD,
  ROLE_UPDATE,
  ROLE_DELETE,
  SEND_VERIFY,
  SEND_REST_PASSWORD,
  USER_GET_EMAIL,
  CONFIRM_VERIFY,
  SEND_REQUEST,
  PAYMENT_GET,
  PAYMENT_ADD,
  PAYMENT_DELETE,
  PROVINCE,
  DISTRICT,
  SUBDISTRICT,
} from "../actions";

import {
  addCompanyprofileSuccess,
  getCompanyprofileSuccess,
  updateCompanyprofileSuccess,
  delCompanyprofileSuccess,
  countryAddressSuccess,
  provinceAddressSuccess,
  districtAddressSuccess,
  subdistrictAddressSuccess,
  // zipcodeAddressSuccess
  getCompanyPkindevSuccess,
  getCreditBankSuccess,
  addProjectSuccess,
  getProjectSuccess,
  setProjectSuccess,
  updateProjectSuccess,
  delProjectSuccess,
  getUserSuccess,
  getRoleSuccess,
  getUserEmailSuccess,
  confirmVerifySuccess,
  getPaymentSuccess,
  getProvince1Success,
  getDistrict1Success,
  getSubdistrict1Success,
} from "../settings/actions";

import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import apiSetting from "../../services/setting";

// ==================Company===================

function* addCompanyprofile({ payload }) {
  try {
    const dataRes = yield apiSetting.addCompanyProfile(payload);

    if (dataRes.status === 1) {
      yield put(addCompanyprofileSuccess(dataRes.data));
    } else {
      //yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    console.log(error);
    
  }
}
function* getCreditBank({ payload }) {
  try {
    const dataRes = yield apiSetting.CreditBank(payload);

    if (dataRes.length != 0) {
      yield put(getCreditBankSuccess(dataRes));
    } else {
      // yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getProvince1({ payload }) {
  try {
    const dataRes = yield apiSetting.provinceAddress(payload);

    if (dataRes.length != 0) {
      yield put(getProvince1Success(dataRes));
    } else {
      // yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getDistrict1({ payload }) {
  try {
    const dataRes = yield apiSetting.districtAddress(payload);

    if (dataRes.length != 0) {
      yield put(getDistrict1Success(dataRes));
    } else {
      // yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getSubdistrict1({ payload }) {
  try {
    const dataRes = yield apiSetting.subdistrictAddress(payload);

    if (dataRes.length != 0) {
      yield put(getSubdistrict1Success(dataRes));
    } else {
      // yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getCompanyPkindev({ payload }) {  ///billing company
  try {
    const dataRes = yield apiSetting.getCompanyPkindev(payload);
     

    if (dataRes.length != 0) {
      yield put(getCompanyPkindevSuccess(dataRes));
      // console.log("getCompanyPkindevSuccess :::",dataRes);///ข้อมูลมาใน DataRes
    } else {
      // yield put(hotelInformationStatusError(`${dataRes.msg}`));
      // console.log("getCompanyPkindev > DataRes == 0");
      
    }
  } catch (error) {
    console.log(error);
    
  }
}
function* getCompanyprofile({ payload }) {
  try {
    const dataRes = yield apiSetting.getCompanyProfile(payload);
    
    if (dataRes.length != 0) {
      yield put(getCompanyprofileSuccess(dataRes));
      //console.log("> getCompanyProfile < dataRes.length !== 0 :::",dataRes);
    } else {
      // yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    console.log(error);
  }
}
function* updateCompanyprofile({ payload }) {
  try {
    const dataRes = yield apiSetting.updateCompanyProfile(payload);

    if (dataRes.status === 1) {
      yield put(updateCompanyprofileSuccess(dataRes.data));
    } else {
      //yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    // yield put(hotelInformationStatusError("OMG! Please wait about 30s and refresh your browser again."));
  }
}
function* delCompanyprofile({ payload }) {
  try {
    const dataRes = yield apiSetting.delCompanyProfile(payload);

    if (dataRes.status === 1) {
      yield put(delCompanyprofileSuccess(dataRes.data));
    } else {
      //yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    // yield put(hotelInformationStatusError("OMG! Please wait about 30s and refresh your browser again."));
  }
}

// ====================Address==================

function* countryAddress({ payload }) {
  try {
    const dataRes = yield apiSetting.countryAddress(payload);

    if (dataRes.length != 0) {
      const new_dataRes = dataRes.map((doc) => {
        return {
          value: doc.idNationality,
          label: doc.nationality_name,
        };
      });
      yield put(countryAddressSuccess(new_dataRes));
    } else {
      // yield put(companyStatusError(dataRes.msg));
    }
  } catch (error) {
    console.log(error);
  }
}
function* provinceAddress({ payload }) {
  try {
    const dataRes = yield apiSetting.provinceAddress(payload);
    if (dataRes.length !== 0) {
      yield put(provinceAddressSuccess(dataRes));
    } else {
      // yield put(companyStatusError("OMG! Please wait about 30s and refresh your browser again."));
    }
  } catch (error) {
    console.log(error);
  }
}
function* districtAddress({ payload }) {
  try {
    const dataRes = yield apiSetting.districtAddress(payload);
    if (dataRes.length !== 0) {
      yield put(districtAddressSuccess(dataRes));
    } else {
      // yield put(companyStatusError("OMG! Please wait about 30s and refresh your browser again."));
    }
  } catch (error) {
    console.log(error);
  }
}
function* subdistrictAddress({ payload }) {
  try {
    const dataRes = yield apiSetting.subdistrictAddress(payload);
    if (dataRes.length !== 0) {
      const new_dataRes = dataRes.map((doc) => {
        return {
          ...doc,
          postal_code: doc.post_code,
        };
      });
      yield put(subdistrictAddressSuccess(new_dataRes));
    } else {
      // yield put(companyStatusError("OMG! Please wait about 30s and refresh your browser again."));
    }
  } catch (error) {
    console.log(error);
  }
}
// function* zipcodeAddress({ payload }) {
//   try {
//     const dataRes = yield apiSetting.zipcodeAddress(payload.subdistrict);
//     if (dataRes.status === 1) {
//       yield put(zipcodeAddressSuccess(dataRes.data));
//     } else {
//       // yield put(companyStatusError("OMG! Please wait about 30s and refresh your browser again."));
//     }
//   } catch (error) {
//     console.log(error)
//   }
// }

// ===================Project=====================

function* addProject({ payload }) {
  try {
    const dataRes = yield apiSetting.addProject(payload);

    if (dataRes.status === 1) {
      yield put(addProjectSuccess(dataRes.data));
    } else {
      //yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    console.log(error);
  }
}
function* setProject({ payload }) {
  try {
    const dataRes = yield apiSetting.setProject(payload);

    if (dataRes.length != 0) {
      yield put(setProjectSuccess(dataRes));
    } else {
      // yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getProject({ payload }) {
  try {
    const dataRes = yield apiSetting.getProject(payload);

    if (dataRes.length != 0) {
      yield put(getProjectSuccess(dataRes));
    } else {
      // yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getProjectInvoice({ payload }) {
  try {
    const dataRes = yield apiSetting.getProjectInvoice(payload);

    if (dataRes.length != 0) {
      yield put(getProjectSuccess(dataRes));
    } else {
      // yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    console.log(error);
  }
}
function* updateProject({ payload }) {
  try {
    const dataRes = yield apiSetting.updateProject(payload);

    if (dataRes.status === 1) {
      yield put(updateProjectSuccess(dataRes.data));
    } else {
      //yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    // yield put(hotelInformationStatusError("OMG! Please wait about 30s and refresh your browser again."));
  }
}
function* delProject({ payload }) {
  try {
    const dataRes = yield apiSetting.delProject(payload);

    if (dataRes.status === 1) {
      yield put(delProjectSuccess(dataRes.data));
    } else {
      //yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    // yield put(hotelInformationStatusError("OMG! Please wait about 30s and refresh your browser again."));
  }
}

// ===================User=====================
function* getUserEmail({ payload }) {
  try {
    const dataRes = yield apiSetting.getUserEmail(payload);
    if (dataRes.length != 0) {
      yield put(getUserEmailSuccess(dataRes));
    } else {
      // yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getUser({ payload }) {
  try {
    const dataRes = yield apiSetting.getUser(payload);
    if (dataRes.length != 0) {
      yield put(getUserSuccess(dataRes));
    } else {
      // yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    console.log(error);
  }
}
function* getRole({ payload }) {
  try {
    const dataRes = yield apiSetting.getRole(payload);
    if (dataRes.length != 0) {
      yield put(getRoleSuccess(dataRes));
    } else {
      // yield put(hotelInformationStatusError(`${dataRes.msg}`));
    }
  } catch (error) {
    console.log(error);
  }
}
function* addRole({ payload }) {
  try {
    const dataRes = yield apiSetting.addRole(payload);
  } catch (error) {
    console.log(error);
  }
}
function* editRole({ payload }) {
  // console.log(payload);
  try {
    const dataRes = yield apiSetting.editRole(payload);
  } catch (error) {
    console.log(error);
  }
}
function* delRole({ payload }) {
  // console.log(payload);
  try {
    const dataRes = yield apiSetting.delRole(payload);
  } catch (error) {
    console.log(error);
  }
}
function* addUser({ payload }) {
  // console.log(payload);
  try {
    const dataRes = yield apiSetting.addUser(payload);
  } catch (error) {
    console.log(error);
  }
}
function* updateUser({ payload }) {
  // console.log(payload);
  try {
    const dataRes = yield apiSetting.updateUser(payload);
  } catch (error) {
    console.log(error);
  }
}
function* updateUserPass({ payload }) {
  // console.log(payload);
  try {
    const dataRes = yield apiSetting.editUserPass(payload);
  } catch (error) {
    console.log(error);
  }
}
function* delUser({ payload }) {
  // console.log(payload);
  try {
    const dataRes = yield apiSetting.delUser(payload);
  } catch (error) {
    console.log(error);
  }
}
function* sendVerify({ payload }) {
  // console.log(payload);
  try {
    const dataRes = yield apiSetting.sendMail(payload);
  } catch (error) {
    console.log(error);
  }
}

function* sendRestPass({ payload }) {
  // console.log(payload);
  try {
    const dataRes = yield apiSetting.sendMailRsetPass(payload);
  } catch (error) {
    console.log(error);
  }
}
function* confirmVerify({ payload }) {
  try {
    const dataRes = yield apiSetting.verifyEmail(payload);
    if (dataRes.length != 0) yield put(confirmVerifySuccess(dataRes));
  } catch (error) {
    console.log(error);
  }
}
function* sendRequest({ payload }) {
  // console.log(payload);
  try {
    const dataRes = yield apiSetting.sendRequest(payload);
  } catch (error) {
    console.log(error);
  }
}

// ===================Payment=====================
function* getPayment({ payload }) {
  try {
    const dataRes = yield apiSetting.getPayment(payload);
    if (dataRes.length != 0) {
      const newData = [
        {
          ...dataRes[0],
          payment_detail: JSON.parse(dataRes[0].payment_detail),
        },
      ];
      yield put(getPaymentSuccess(newData));
    } else {
      yield put(getPaymentSuccess([]));
    }
  } catch (error) {
    console.log(error);
  }
}
function* addPayment({ payload }) {
  try {
    const dataRes = yield apiSetting.addPayment(payload);
  } catch (error) {
    console.log(error);
  }
}
function* deletePayment({ payload }) {
  try {
    const dataRes = yield apiSetting.deletePayment(payload);
  } catch (error) {
    console.log(error);
  }
}

export function* watchGetCreditBank() {
  yield takeEvery(CREDITBANK_GET, getCreditBank);
}
export function* watchAddCompanyProfile() {
  yield takeEvery(COMPANY_PROFILE_ADD, addCompanyprofile);
}
export function* watchGetCompanyPkindev() {
  yield takeEvery(COMPANY_PKINDEV, getCompanyPkindev);
}
export function* watchGetCompanyProfile() {
  yield takeEvery(COMPANY_PROFILE_GET, getCompanyprofile);
}
export function* watchUpdateCompanyProfile() {
  yield takeEvery(COMPANY_PROFILE_UPDATE, updateCompanyprofile);
}
export function* watchDelCompanyProfile() {
  yield takeEvery(COMPANY_PROFILE_DELETE, delCompanyprofile);
}
export function* watchCountryAddress() {
  yield takeEvery(COMPANY_PROFILE_COUNTRY, countryAddress);
}
export function* watchProvinceAddress() {
  yield takeEvery(COMPANY_PROFILE_PROVINCE, provinceAddress);
}
export function* watchDistrictAddress() {
  yield takeEvery(COMPANY_PROFILE_DISTRICT, districtAddress);
}
export function* watchSubDistrictAddress() {
  yield takeEvery(COMPANY_PROFILE_SUBDISTRICT, subdistrictAddress);
}
// export function* watchZipCodeAddress() {
//   yield takeEvery(COMPANY_PROFILE_ZIPCODE, zipcodeAddress);
// }
export function* watchAddProject() {
  yield takeEvery(PROJECT_ADD, addProject);
}
export function* watchGetProject() {
  yield takeEvery(PROJECT_GET, getProject);
}
export function* watchGetProjectInvoice() {
  yield takeEvery(PROJECT_GET_INVOICE, getProjectInvoice);
}
export function* watchSetProject() {
  yield takeEvery(PROJECT_SET, setProject);
}
export function* watchUpdateProject() {
  yield takeEvery(PROJECT_UPDATE, updateProject);
}
export function* watchDelProject() {
  yield takeEvery(PROJECT_DELETE, delProject);
}

export function* watchGetUserEmail() {
  yield takeEvery(USER_GET_EMAIL, getUserEmail);
}
export function* watchGetUser() {
  yield takeEvery(USER_GET, getUser);
}
export function* watchGetRole() {
  yield takeEvery(ROLE_GET, getRole);
}
export function* watchAddRole() {
  yield takeEvery(ROLE_ADD, addRole);
}
export function* watchEditRole() {
  yield takeEvery(ROLE_UPDATE, editRole);
}
export function* watchDelRole() {
  yield takeEvery(ROLE_DELETE, delRole);
}
export function* watchAddUser() {
  yield takeEvery(USER_ADD, addUser);
}
export function* watchUpdateUser() {
  yield takeEvery(USER_UPDATE, updateUser);
}
export function* watchUpdateUserPass() {
  yield takeEvery(USER_PASS_UPDATE, updateUserPass);
}
export function* watchDelUser() {
  yield takeEvery(USER_DELETE, delUser);
}
export function* watchSendVerify() {
  yield takeEvery(SEND_VERIFY, sendVerify);
}
export function* watchGetPayment() {
  yield takeEvery(PAYMENT_GET, getPayment);
}
export function* watchAddPayment() {
  yield takeEvery(PAYMENT_ADD, addPayment);
}
export function* watchDeletePayment() {
  yield takeEvery(PAYMENT_DELETE, deletePayment);
}
export function* watchSendRestPass() {
  yield takeEvery(SEND_REST_PASSWORD, sendRestPass);
}
export function* watchConfirmVerify() {
  yield takeEvery(CONFIRM_VERIFY, confirmVerify);
}
export function* watchSendRequest() {
  yield takeEvery(SEND_REQUEST, sendRequest);
}

export function* watchGetProvince1() {
  yield takeEvery(PROVINCE, getProvince1);
}
export function* watchGetDistrict1() {
  yield takeEvery(DISTRICT, getDistrict1);
}
export function* watchGetSubdistrict1() {
  yield takeEvery(SUBDISTRICT, getSubdistrict1);
}

export default function* rootSaga() {
  yield all([
    // =========Company=======
    fork(watchAddCompanyProfile),
    fork(watchGetCompanyProfile),
    fork(watchUpdateCompanyProfile),
    fork(watchDelCompanyProfile),
    fork(watchCountryAddress),
    fork(watchProvinceAddress),
    fork(watchDistrictAddress),
    fork(watchSubDistrictAddress),
    // fork(watchZipCodeAddress)
    fork(watchGetCompanyPkindev),
    fork(watchGetCreditBank),
    // ==========Project==========
    fork(watchAddProject),
    fork(watchGetProject),
    fork(watchGetProjectInvoice),
    fork(watchSetProject),
    fork(watchUpdateProject),
    fork(watchDelProject),
    // ==========User==========
    fork(watchGetUserEmail),
    fork(watchGetUser),
    fork(watchGetRole),
    fork(watchAddRole),
    fork(watchEditRole),
    fork(watchDelRole),
    fork(watchAddUser),
    fork(watchUpdateUser),
    fork(watchUpdateUserPass),
    fork(watchDelUser),
    fork(watchSendVerify),
    fork(watchSendRestPass),
    fork(watchConfirmVerify),
    fork(watchSendRequest),
    // ==========Payment==========
    fork(watchGetPayment),
    fork(watchAddPayment),
    fork(watchDeletePayment),

    fork(watchGetProvince1),
    fork(watchGetDistrict1),
    fork(watchGetSubdistrict1),
  ]);
}
